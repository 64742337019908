<template>
  <div
    class="member-card cursor-pointer"
    :class="{
      'person-card--disabled': disabled,
      'show-more': showMore,
    }"
    :to="{
      name: 'profile',
      params: {
        group: groupSlug,
        user: profile.slug,
      },
    }"
    @click="click"
    :event="!disabled ? 'click' : ''"
    @mouseover="!disabled ? (isHovered = true) : null"
    @mouseleave="!disabled ? (isHovered = false) : null"
  >
    <div class="relative w-130px sm:w-full">
      <div
        :class="{ 'rounded-tl': showMore, 'rounded-l': !showMore }"
        class="sm:rounded-t sm:rounded-l-none overflow-hidden relative bg-gray-300 h-150px sm:h-auto w-130px sm:w-full"
      >
        <ProfilePhoto :is-card="true" :inactive="inactive" :url="profile.profilePicture" />
        <div
          v-if="profile.isDeceased"
          class="absolute bottom-2 left-2 flex items-center justify-center rounded w-[120px] h-[21px] bg-gray-900 text-white text-xs font-semibold"
        >
          In Memoriam
        </div>
      </div>
      <div class="flex absolute top-0 right-0 mr-2 mt-2 sm:mr-3 sm:mt-3">
        <div
          v-if="!isPublic && (isHovered || profile.isOnMyList)"
          @click.prevent.stop="$emit('setOnYourList', !profile.isOnMyList)"
          class="icon-wrapper animation-focus-default"
        >
          <BookmarkIcon
            :class="{ active: profile.isOnMyList }"
            class="on-your-list-icon h-2 w-2 sm:h-3 sm:w-3"
          />
        </div>
        <div v-if="profile.isGroupAdmin" class="icon-wrapper ml-2">
          <StarIcon class="h-2 w-2 sm:h-3 sm:w-3" />
        </div>
        <div
          v-if="profile.isManagedByAdmin && isShowAdminLabel"
          v-tooltip="'Created by Admin'"
          class="icon-wrapper ml-2"
        >
          <IconAdmin class="h-2 w-2 sm:h-3 sm:w-3 icon-admin" />
        </div>
      </div>
    </div>

    <div
      :class="{
        'show-more sm:max-h-full h-full sm:h-82%': showMore,
        'sm:max-h-33% h-82%': !showMore,
        'show-label': isDataOverflow,
      }"
      class="card-information px-6 py-4 sm:px-5 sm:py-3 w-60% sm:w-auto overflow-hidden sm:h-auto"
    >
      <h5 class="font-serif sm:font-sans w-full text-left" :class="{ 'mb-2': !previousName }">
        {{ memberName }}
      </h5>
      <p v-if="previousName" class="font-serif sm:font-sans w-full text-left mb-2">
        <span class="text-gray-600 text-sm">({{ previousName }})</span>
      </p>
      <div class="relative flex-grow">
        <div v-if="profile.city" class="flex mb-2">
          <div class="w-6 h-5 ml-1 mr-2 flex items-center">📍</div>
          <p class="w-36 sm:w-full text-left text-small self-center">
            <template v-if="profile.city">{{ profile.city }}</template>
            <template v-if="profile.state">, {{ profile.state }}</template>
          </p>
        </div>
        <div v-if="profile.school && showSchool" class="flex mb-2">
          <div class="w-5 h-5 mr-3 flex items-center">🏛️</div>
          <p class="w-full text-left text-small self-center">
            {{ profile.school }}
          </p>
        </div>
        <div v-if="profile.major && showMajor" class="flex mb-2">
          <div class="w-5 h-5 mr-3 flex items-center">🏛️</div>
          <p class="w-full text-left text-small self-center">
            {{ profile.major }}
          </p>
        </div>
        <div v-if="profile.employer && showEmployer" class="flex mb-2">
          <div class="w-5 h-5 mr-3 flex items-center">💼</div>
          <p class="w-full text-left text-small self-center">
            {{ profile.employer }}
          </p>
        </div>
        <template v-for="field in displayedFields">
          <div v-if="!!field.value" class="flex mb-2" :key="field.slug">
            <div class="w-5 h-5 mr-3 flex items-center">
              {{ field.fieldIcon || '' }}
            </div>
            <p class="w-full text-left text-small self-center">
              {{ field.value }}
            </p>
          </div>
        </template>
      </div>

      <div
        v-show="showDataOverflow && isDataOverflow && isHovered"
        @click="
          $event.preventDefault();
          $event.stopPropagation();
          toggleShowMore();
        "
        class="show-more-label hidden sm:block text-small absolute bottom-2 right-2 px-2 py-1 rounded bg-gray-200 text-center min-w-90px animation-focus-default z-50"
      >
        Show {{ !showMore ? 'more' : 'less' }}
      </div>

      <div
        v-show="showDataOverflow && isDataOverflow"
        @click="
          $event.preventDefault();
          $event.stopPropagation();
          toggleShowMore();
        "
        class="show-more-label mobile block sm:hidden absolute bottom-2 right-2 px-2 py-2 rounded bg-gray-200"
      >
        <ChevronDownIcon :class="{ opened: showMore }" class="chevron-icon w-3 h-3" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import StarIcon from '@/assets/icon_star.svg';
import ProfilePhoto from '@/components/ProfilePhoto.vue';
import ChevronDownIcon from '@/assets/icon_chevron_up.svg';
import BookmarkIcon from '@/assets/icon_bookmark.svg';
import IconAdmin from '@/assets/icon_user-admin.svg';

export default {
  name: 'ProfileCard',

  components: {
    StarIcon,
    IconAdmin,
    ProfilePhoto,
    ChevronDownIcon,
    BookmarkIcon,
  },
  data() {
    return {
      isHovered: false,
      showMore: false,
      isDataOverflow: false,
    };
  },
  props: {
    profile: {
      type: Object,
      required: true,
    },
    /*
     * The user's group slug
     */
    groupSlug: {
      type: String,
    },
    /*
     * Whether the user can interact with the profile card or not
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    showDataOverflow: {
      type: Boolean,
      default: false,
    },
    /*
     * An array of custom field slugs that should be visible on the Members
     */
    visibleFields: {
      type: Array,
      default: () => [],
    },
    showSchool: {
      type: Boolean,
    },
    showEmployer: {
      type: Boolean,
    },
    showMajor: {
      type: Boolean,
    },
    setOnYourList: {
      type: Function,
    },
    isPublic: {
      type: Boolean,
      default: false,
    },
    isShowAdminLabel: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    previousName() {
      return `${this.profile.previousFirstName ? this.profile.previousFirstName : ''} ${
        this.profile.previousLastName ? this.profile.previousLastName : ''
      }`.trim();
    },
    memberName() {
      return `${this.profile.firstName} ${this.profile.lastName ? this.profile.lastName : ''}`;
    },
    inactive() {
      return this.profile.status === 'pending' || this.profile.status === 'suspended';
    },
    displayedFields() {
      const customFields = this.profile.customGroupValues;
      return customFields
        ? customFields.filter((field) => this.visibleFields.includes(field.fieldSlug))
        : null;
    },
  },

  mounted() {
    const dataHeight = document.querySelector('.card-information')?.scrollHeight;
    const displayHeight = document.querySelector('.card-information')?.clientHeight;

    if (dataHeight && dataHeight > displayHeight) {
      this.isDataOverflow = true;
    }
  },
  methods: {
    toggleShowMore() {
      this.showMore = !this.showMore;
    },
    closeShowMore() {
      this.showMore = false;
    },
    click() {
      if (this.disabled) {
        return;
      }
      if (this.isPublic) {
        this.$router.push({
          name: 'public-profile',
          params: {
            group: this.groupSlug,
            user: this.profile.slug,
          },
        });
      } else {
        this.$router.push({
          name: 'profile',
          params: {
            group: this.groupSlug,
            user: this.profile.slug,
          },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.school-icon {
  margin-left: 0.125rem;
  margin-right: 0.375rem;
}

.member-card {
  &.person-card--disabled {
    @apply cursor-default pointer-events-none;
  }
}

.show-more-label {
  &.mobile .chevron-icon {
    transform: rotate(180deg);

    &.opened {
      transform: rotate(0deg);
    }
  }
}

.icon-wrapper {
  @apply p-1 sm:p-2 bg-white rounded-full border hover:border-gray-800;

  .on-your-list-icon {
    &.active {
      fill: #009765;
    }
  }
}

.icon-admin {
  fill: #8a4418;
}

.description-icon {
  margin-top: 1px;
  width: 14px;
  height: 14px;
}

.fade-up-enter-active,
.fade-up-leave-active {
  transition: opacity 0.2s ease, transform 0.2s ease;
}

.fade-up-enter,
.fade-up-leave-to {
  opacity: 0;
  transform: translateY(10px);
}
</style>
