<template>
  <div class="badge"
       :class="{'short': isShort, 'white': isWhite}">
    <div class="container"
         :style="{
          background: !isTransparent ? `rgba(${background})` : 'transparent',
        }"
      >
      <div v-if="isShort"
           class="badge-text text-sm font-medium"
           :class="{ 'pr-2': icon}">
        {{ text }}
      </div>

      <CalendarIcon v-if="showCalendarIcon" class="flex-shrink-0 w-4"/>
      <HeartIcon v-else-if="showHeartIcon" class="flex-shrink-0 w-4"/>
      <LocationIcon v-else-if="showLocationIcon" class="flex-shrink-0 w-4"/>
      <AttendingIcon v-else-if="showAttendingIcon" class="flex-shrink-0 w-4"/>

      <AcademicIcon v-else-if="showAcademicIcon" class="flex-shrink-0 w-4"/>
      <WorkIcon v-else-if="showWorkIcon" class="flex-shrink-0 w-4"/>
      <FamilyIcon v-else-if="showFamilyIcon" class="flex-shrink-0 w-5 family-icon"/>
      <ClubIcon v-else-if="showClubIcon" class="flex-shrink-0 w-4"/>
      <DiamondIcon v-else-if="showDiamondIcon" class="flex-shrink-0 w-4"/>
      <PeopleIcon v-else-if="showPeopleIcon" class="flex-shrink-0 w-4"/>

      <div v-else-if="showSchoolIcon" class="w-5 h-5">
        <SchoolIcon class="flex-shrink-0 width-full mt-1"/>
      </div>
      <div v-else-if="showEmployerIcon" class="w-4 h-4">
        <EmployerIcon class="flex-shrink-0 width-full"/>
      </div>
      <div v-else-if="icon" class="leading-3 w-4">
        <img :src="icon" :alt="icon"/>
      </div>
      <div v-if="!isShort" class="badge-text text-sm font-medium capitalize" :class="{ 'pl-2': icon }">
        {{ text }}
        <span class="font-bold" v-if="boldText">
        {{ boldText }}
      </span>
      </div>
    </div>

    <div class="background" :style="{
      'background-color': isTransparent ? 'rgba(0,0,0,0.2)' : `transparent`,
    }"
    ></div>
  </div>
</template>

<script>
import LocationIcon from '@/assets/location_icon.svg';
import AttendingIcon from '@/assets/attending_icon.svg';
import CalendarIcon from '@/assets/icon_calendar.svg';
import HeartIcon from '@/assets/icon_heart.svg';
import SchoolIcon from '@/assets/grad_cap.svg';
import EmployerIcon from '@/assets/briefcase.svg';
import AcademicIcon from '@/assets/icon_academic.svg';
import WorkIcon from '@/assets/icon_work.svg';
import FamilyIcon from '@/assets/icon_home.svg';
import ClubIcon from '@/assets/icon_users.svg';
import DiamondIcon from '@/assets/diamond.svg';
import PeopleIcon from '@/assets/people.svg';

export default {
  name: 'Badge',
  components: {
    LocationIcon,
    AttendingIcon,
    CalendarIcon,
    HeartIcon,
    EmployerIcon,
    SchoolIcon,
    AcademicIcon,
    WorkIcon,
    FamilyIcon,
    ClubIcon,
    DiamondIcon,
    PeopleIcon,
  },
  props: {
    /**
     * text to be displayed
     */
    text: {
      type: String,
      required: true,
      default: () => '',
    },
    /**
     * bold subtext to be displayed
     */
    boldText: {
      type: String,
      required: false,
    },
    /**
     * icon to be displayed
     */
    icon: {
      type: String,
    },
    /**
     * color for badge background
     */
    background: {
      type: String,
    },
    /**
     * type of the badge: full width or expand on hover
     */
    isShort: {
      type: Boolean,
    },
    /**
     * color of a text and svg
     */
    isWhite: {
      type: Boolean,
    },
    /**
     * color of a background
     */
    isTransparent: {
      type: Boolean,
    },
  },
  computed: {
    showLocationIcon() {
      return this.icon === 'location';
    },
    showAttendingIcon() {
      return this.icon === 'attending';
    },
    showCalendarIcon() {
      return this.icon === 'calendar';
    },
    showHeartIcon() {
      return this.icon === 'heart';
    },
    showSchoolIcon() {
      return this.icon === 'school';
    },
    showEmployerIcon() {
      return this.icon === 'employer';
    },
    showAcademicIcon() {
      return this.icon === 'academic';
    },
    showWorkIcon() {
      return this.icon === 'work';
    },
    showFamilyIcon() {
      return this.icon === 'family';
    },
    showClubIcon() {
      return this.icon === 'club';
    },
    showDiamondIcon() {
      return this.icon === 'diamond';
    },
    showPeopleIcon() {
      return this.icon === 'people';
    },
  },
};
</script>

<style lang="scss">
.badge {
  @apply relative inline-flex;

  .container {
    @apply relative inline-flex py-2 px-4 items-center overflow-hidden;
    border-radius: 40px;
    z-index: 2;
  }

  &.white {
    .container {
      @apply py-1 lg:py-2;

      .badge-text {
        color: #ffffff;
      }

      svg path {
        stroke: #ffffff;
      }
    }
  }

  &.short {
    .container {
      @apply h-auto p-3 flex items-center justify-end relative;
      transition: max-width 0.1s ease-in-out, transform 0.2s ease;
      max-width: 36px;
      height: 36px;
      width: 36px;
      border-radius: 36px;

      .family-icon {
        margin-right: -4px;
      }

      svg {
        margin-top: -2px;
        margin-right: -2px;
      }

      .badge-text {
        font-size: 12px;
        display: flex;
        align-items: center;
        opacity: 0;
        text-transform: capitalize;
        width: 0;
        padding-right: 0;
        height: 36px;
      }
    }

    &:hover {
      .container {
        width: auto;
        max-width: 200px;

        .badge-text {
          opacity: 1;
          width: auto;
          padding-right: 8px;
        }
      }
    }
  }

  .background {
    @apply absolute w-full h-full top-0 left-0;
    z-index: 1;
    border-radius: 40px;
  }
}
</style>
