var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"py-8 sm:py-12 mx-6 sm:mx-auto max-w-screen-xl border-t border-gray-300 sm:border-none"},[_c('div',{staticClass:"flex flex-col sm:flex-row items-start sm:items-center justify-between"},[_c('div',{staticClass:"flex flex-col sm:flex-row sm:justify-between items-start sm:items-center sm:bg-transparent pb-4 sm:pb-8 rounded w-full"},[_c('h3',[_vm._v(_vm._s(_vm.profiles.length)+" "+_vm._s(_vm._f("pluralize")('Member',_vm.profiles.length)))]),_c('div',{staticClass:"mt-5 sm:mt-0 w-full sm:w-470px block relative"},[_c('TextInput',{attrs:{"id":"search","placeholder":"Search","label":"Search","is-search":true,"value":_vm.searchValue,"has-clear-icon":true},on:{"focused":_vm.searchFocused,"input":_vm.updateSearch,"onKeyEnter":function($event){return _vm.searchEnterHit(true)}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('SearchIcon',{staticClass:"w-4"})]},proxy:true}])}),(_vm.filteredProfiles.length && _vm.searchValue.length && _vm.showFilterResults)?_c('div',{staticClass:"search-dropdown-container"},[_c('div',{staticClass:"search-dropdown custom-scroll"},_vm._l((_vm.filteredProfiles),function(profile,index){return _c('div',{key:index,staticClass:"search-profile-item",class:{ 'mb-4': index !== _vm.filteredProfiles.length - 1 }},[_c('router-link',{staticClass:"flex items-center cursor-pointer",attrs:{"to":{
                  name: 'public-profile',
                  params: {
                    group: _vm.group.slug,
                    user: profile.slug,
                  },
                }}},[_c('img',{staticClass:"rounded-full w-9 h-9 mr-4",attrs:{"src":profile.profilePicture,"alt":"profile.profile_picture"}}),_c('div',{staticClass:"text-sm text-gray-500",domProps:{"innerHTML":_vm._s(
                    _vm.$options.filters.highlight(
                      profile.firstName + ' ' + profile.lastName,
                      _vm.searchValue
                    )
                  )}},[_vm._v(" "+_vm._s(profile.firstName)+" "+_vm._s(profile.lastName)+" ")])])],1)}),0)]):_vm._e()],1)])]),_c('div',[_c('div',{staticClass:"flex flex-wrap sm:min-h-364px",class:{ 'min-h-170px': !_vm.isLoading }},[(!_vm.profiles.length)?_c('div',{staticClass:"hidden sm:flex justify-between flex-nowrap overflow-hidden w-full md:w-720px xl:w-970px 2xl:w-full"},_vm._l((Array(5)),function(emptyCard){return _c('LoadingProfileCard',{key:emptyCard,staticClass:"mx-auto"})}),1):_vm._e(),(!_vm.profiles.length)?_c('div',{staticClass:"flex sm:hidden w-full h-150px"},[_c('LoadingProfileCard')],1):_vm._e(),(_vm.profiles.length)?_c('div',{staticClass:"grid grid-cols-1 sm:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-4 sm:gap-7 lg:gap-5 2xl:gap-4 3xl:gap-9"},_vm._l((_vm.cardProfilesShown),function(profile){return _c('ProfileCard',{key:profile.id,attrs:{"profile":profile,"groupSlug":_vm.group.slug,"visibleFields":_vm.visibleCustomFieldSlugs,"showSchool":_vm.group.schoolShowYearbook,"showEmployer":_vm.group.employerShowYearbook,"showMajor":_vm.group.majorShowYearbook,"showDataOverflow":"","isPublic":"","isShowAdminLabel":_vm.groupFeatureFlags && _vm.groupFeatureFlags.createdByAdminLabelEnabled},on:{"setOnYourList":function($event){return _vm.setOnYourList(profile)}},nativeOn:{"click":function($event){return _vm.profileLinkClicked.apply(null, arguments)}}})}),1):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }