<template>
  <section
    ref="modal"
    :class="{
      'flex w-full h-full fixed justify-center items-center inset-0 outline-none z-50': isModal,
    }"
    tabindex="0"
    class="focus:outline-none"
    @keydown.esc="close"
  >
    <div v-if="isModal" class="bg-black opacity-25 w-full h-full fixed z-50" />

    <div
      :class="{
        'modal bg-white sm:h-auto absolute top-app-header lg:max-w-auto lg:top-auto mt-5 lg:mt-0 custom-scroll py-6 pl-6 sm:px-10 sm:pb-10 sm:pt-6 lg:pt-8 flex flex-col rounded z-50 w-314px sm:max-w-100vw sm:w-600px lg:max-w-4xl lg:w-216':
          isModal,
        'pr-2 h-80vh': !invitesSent,
        'pr-6 h-auto': invitesSent,
      }"
    >
      <button
        v-if="!invitesSent && isModal"
        class="absolute right-5 top-5 focus:outline-none animation-focus-default"
        type="text"
        @click="close"
      >
        <CloseIcon class="close-icon w-6 h-6"></CloseIcon>
      </button>
      <template v-if="invitesSent">
        <div class="flex items-center">
          <SuccessIcon />
          <h1 class="font-serif text-xl sm:text-3xl font-bold text-gray-900 pl-3">
            Invitations sent!
          </h1>
        </div>
        <p class="text-gray-600 py-4 font-sans text-base sm:text-lg">
          Congrats! Email invites were sent to
          <span class="font-bold">{{ getInvitesSentCount() }}</span>
          with instructions to join your group.
        </p>
        <div class="self-end" v-if="isModal">
          <Button :primary-full-size="false" type="primary" @onClick="close"> Close </Button>
        </div>
      </template>

      <template v-else>
        <h1
          v-if="isModal"
          class="font-serif pb-2 sm:pb-0 text-xl sm:text-2xl text-gray-900 font-normal leading-[140%]"
        >
          Invite Member
        </h1>

        <div class="pr-2 sm:pr-0" :class="{ 'overflow-y-auto sm:overflow-visible': !invitesSent }">
          <p
            v-if="isModal"
            class="text-gray-900 py-2 sm:py-4 font-normal text-small sm:text-sm lg:text-base sm:mb-0"
          >
            Anyone can invite others to join your group by sending a text, email, or link.
          </p>
          <div
            class="flex items-center rounded border border-gray-300 pl-5 pr-3 py-2 sm:py-[13px] border-solid mb-2 sm:mb-4"
          >
            <p class="link text-gray-700 text-small sm:text-sm lg:text-lg truncate">
              {{ signupLink }}
            </p>
            <div class="items-center flex ml-auto">
              <SuccessIcon v-if="copySuccessful" />
              <button
                type="button"
                class="w-[30px] h-[30px] focus:outline-none animation-focus-default rounded flex justify-center items-center"
                v-clipboard="signupLink"
                v-clipboard:success="clipboardSuccessHandler"
                v-clipboard:error="clipboardErrorHandler"
              >
                <LinkIcon class="w-3.5" />
              </button>
            </div>
          </div>
          <div>
            <div class="flex flex-col lg:flex-row gap-2">
              <a :href="mailToLink" class="sharing-link flex-1 mb-0 lg:mb-4">
                <div
                  class="flex items-center gap-3 border border-gray-200 py-2 px-3 rounded-lg border-solid"
                >
                  <span
                    class="bg-gray-100 rounded w-[28px] h-[28px] flex justify-center items-center"
                  >
                    <EmailIcon class="w-[16px]" />
                  </span>
                  <span class="text-small sm:text-sm font-bold rounded justify-between text-sans">
                    Share via email
                  </span>
                </div>
              </a>
              <a
                v-clipboard="signupLink"
                v-clipboard:success="clipboardTextSuccessHandler"
                v-clipboard:error="clipboardErrorHandler"
                :href="smsLink"
                class="sharing-link flex-1 mb-2 sm:mb-4"
              >
                <div
                  class="flex items-center gap-3 border border-gray-200 py-2 px-3 rounded-lg border-solid"
                >
                  <div class="flex items-center">
                    <SuccessIcon class="ml-auto mr-2" v-if="copyTextSuccessful" />
                    <span
                      class="bg-gray-100 rounded w-[28px] h-[28px] flex justify-center items-center"
                    >
                      <PhoneIcon class="w-[16px]" />
                    </span>
                  </div>
                  <span class="text-small sm:text-sm font-bold rounded justify-between text-sans">
                    Copy & Open Messaging App
                  </span>
                </div>
              </a>
            </div>
          </div>
          <p class="text-gray-900 pb-2 sm:pb-4 font-sans text-small sm:text-sm lg:text-base">
            Add email addresses (separated by commas) below and we’ll send an invite on your behalf.
          </p>
          <TextArea
            id="emails"
            placeholder="List Emails to invite"
            @input="updateEmails"
            :autocapitalize="false"
            compact
          />
          <div class="pt-2 sm:pt-4 flex justify-end">
            <div class="w-full md:w-[200px]">
              <Button
                type="primary"
                @onClick="handleSendEmails"
                :disabled="buttonIsDisabled"
                :loading="isLoading"
              >
                Send Invites
              </Button>
            </div>
          </div>

          <template v-if="errorMessage">
            <ErrorBox :message="errorMessage" />
          </template>
        </div>
      </template>
    </div>
  </section>
</template>

<script>
import TextArea from '@/components/TextArea.vue';
import Button from '@/components/Button.vue';
import ErrorBox from '@/components/ErrorBox.vue';
import { mapActions, mapGetters } from 'vuex';
import SuccessIcon from '@/assets/success_icon.svg';
import LinkIcon from '@/assets/icon_link.svg';
import PhoneIcon from '@/assets/icon_phone.svg';
import EmailIcon from '@/assets/icon_mail.svg';
import CloseIcon from '@/assets/icon_close.svg';
import constants from '@/config/constants';

const { SUBDOMAIN } = constants;

export default {
  name: 'GroupInviteModal',
  components: {
    TextArea,
    Button,
    ErrorBox,
    SuccessIcon,
    LinkIcon,
    CloseIcon,
    PhoneIcon,
    EmailIcon,
  },
  data() {
    return {
      invitesSent: false,
      emailList: '',
      signupLink: '',
      copySuccessful: false,
      copyTextSuccessful: false,
      errorMessage: '',
    };
  },
  props: {
    /**
     * Hide modal to exit
     */
    hideModal: {
      type: Function,
    },
    isModal: {
      type: Boolean,
      default: true,
    },
  },
  created() {
    this.signupLink =
      this.group && this.group.slug
        ? `https://${SUBDOMAIN}.reunacy.com/group/${this.group.slug}/signup`
        : 'Something went wrong!';
  },
  mounted() {
    if (this.isModal) {
      document.body.style.overflow = 'hidden';
      // used to allow closing with esc button
      if (this.$refs.modal) {
        this.$refs.modal.focus();
      }
    }
  },
  unmounted() {
    document.body.style.overflow = 'auto';
  },
  computed: {
    ...mapGetters(['isLoading', 'group']),
    buttonIsDisabled() {
      return this.isLoading || !this.emailList;
    },
    mailToLink() {
      const subject = '?subject=You%20have%20been%20invited%20to%20join%20a%20Reunacy%20group!';
      const subtitle = this.group.subtitle ? `:%20${this.group.subtitle}` : '';
      const body = `&body=Use%20the%20following%20link%20to%20join%20the%20group%20${this.group.name}${subtitle}:`;
      const membersLink = `https://${SUBDOMAIN}.reunacy.com/group/${this.group.slug}/members`;
      const members = `Want%20to%20see%20who's%20in%20the%20group%20first?%20Check%20out%20the%20group's%20members%20here:%0d%0a${membersLink}`;
      return `mailto:${subject}${body}%0d%0a${this.signupLink}%0d%0a%0d%0a${members}`;
    },
    smsLink() {
      const ios = !!navigator.platform && /iPad|iPhone|iPod|MacIntel/.test(navigator.platform);
      const start = ios ? 'sms: &body=' : 'sms:?body=';
      const subtitle = this.group.subtitle ? `:%20${this.group.subtitle}` : '';
      return `${start}Hey,%20you%20should%20join%20our%20Reunacy%20group%20${this.group.name}${subtitle}%20with%20this%20link%20${this.signupLink}`;
    },
  },
  methods: {
    ...mapActions(['sendGroupInvite']),
    updateEmails(emails) {
      this.emailList = emails;
    },
    convertListToArray() {
      return this.emailList
        .split(',')
        .map((email) => email.trim())
        .filter((email) => !!email);
    },
    handleSendEmails() {
      // Google Analytics Event
      this.$gtag.event('sent_invites', {
        event_category: 'members',
        event_label: `${this.$route.params.group} / Sent group invites`,
      });

      const groupName = `${this.group.name}${
        this.group.subtitle ? `: ${this.group.subtitle}` : ''
      }`;

      const emailList = this.convertListToArray();
      this.sendGroupInvite({
        groupSlug: this.group.slug,
        emails: emailList,
        message: `You've been invited to join the Reunacy group ${groupName}!`,
      }).then((response) => this.handleEmailInviteResponse(response));
    },
    handleEmailInviteResponse(response) {
      if (response.success) {
        this.errorMessage = '';
        this.invitesSent = true;
      } else {
        this.errorMessage =
          'Your invites could not be sent. Make sure emails listed are valid and comma-separated.';
      }
    },
    getInvitesSentCount() {
      const count = this.convertListToArray().length;
      const people = count === 1 ? 'person' : 'people';
      return `${count} ${people}`;
    },
    close() {
      this.hideModal();
    },
    clipboardSuccessHandler() {
      this.errorMessage = '';
      this.copySuccessful = true;
    },
    clipboardTextSuccessHandler() {
      this.errorMessage = '';
      this.copyTextSuccessful = true;
    },
    clipboardErrorHandler() {
      this.errorMessage = 'Oops! Your link could not be copied. Try again.';
      this.copySuccessful = false;
      this.copyTextSuccessful = false;
    },
  },
};
</script>

<style scoped>
.link {
  overflow-wrap: break-word;
}

.modal {
  left: 50%;
  transform: translateX(-50%);
}

.close-icon path {
  stroke: #c8cacd;
}
</style>
