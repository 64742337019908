<template>
  <img
    v-if="url && !hasError"
    :src="url"
    @error="hasError = true"
    :class="{ 'opacity-50': inactive, card: isCard, 'picture block mx-auto': !isPrintView }"
  />
  <img
    v-else-if="useGroupPlaceholder"
    src="@/assets/group_photo_outline.png"
    class="picture block mx-auto opacity-8"
  />
  <img
    v-else
    src="@/assets/profile_photo_outline.png"
    class=""
    :class="{ 'picture block mx-auto': !isPrintView }"
  />
</template>

<script>
export default {
  name: 'ProfilePhoto',
  props: {
    /*
     * The url of the Profile Photo
     */
    url: {
      type: String,
    },
    /*
     * inactive is user is pending or suspended
     */
    inactive: {
      type: Boolean,
    },
    /*
     * whether is the photo for profile card
     */
    isCard: {
      type: Boolean,
    },
    useGroupPlaceholder: {
      type: Boolean,
      default: () => false,
    },
    isPrintView: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      hasError: false,
    };
  },
};
</script>

<style scoped>
.picture.card {
  @apply h-150px w-auto sm:w-full sm:h-auto;
  min-width: 0;
  max-width: 300px;
}

@media all and (max-width: 576px) {
  .picture.card {
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
  }

  .picture.print {
    @apply mx-0;
  }
}
</style>
