<template>
  <div class="">

    <!--                        -->
    <!-- RECORD VIDEO CONTAINER -->
    <!--                        -->

    <template v-if="!videoInfo && onOwnProfile">
      <div
        class="flex bg-white rounded shadow-drop border border-gray-300 px-2 py-9 sm:p-8 flex-col sm:flex-row items-center justify-between">
        <div class="flex flex-col justify-center items-center sm:justify-start sm:items-start sm:flex-row">
          <span class="mb-3 sm:mb-0 sm:mr-6 p-5 sm:p-6 rounded-full bg-gray-100 h-max">
            <VideoIcon class="w-7 h-7 sm:w-10 sm:h-10"></VideoIcon>
          </span>
          <div>
            <h3 class="text-base sm:text-2xl text-center sm:text-left">Record a video story</h3>
            <p class="text-sm mt-3 sm:mt-2 max-w-300px text-center sm:text-left">
              {{
                safariDesktop
                  ? 'Sorry, video recording is not supported by Safari on desktop.' +
                  ' Please try Chrome or Firefox.'
                  : 'Choose a prompt, record a short response, and add it to your profile.'
              }}
            </p>
          </div>
        </div>
        <Button
          :disabled="safariDesktop"
          class="sm:ml-9 mt-4 sm:mt-0 w-155px max-w-155px bg-primary"
          :class="{'w-175px': safariDesktop}"
          type="primary" color-custom
          @onClick="openModal"
        >
          <span class="text-sm sm:text-base text-white">Record video</span>
        </Button>
      </div>
    </template>

    <!--                      -->
    <!-- VIEW VIDEO CONTAINER -->
    <!--                      -->

    <template v-else>
      <div class="flex bg-white rounded shadow-drop border border-gray-300 flex-col">

        <div class="flex flex-col-reverse sm:flex-row sm:items-center sm:justify-between">
          <div class="flex flex-col justify-content">
            <div v-if="!showEdit" class="p-6 sm:p-8 sm:max-w-400px sm:w-400px">
              <div class="text-sm flex mb-2">
                <VideoCameraIcon class="w-5 h-5 mr-2"></VideoCameraIcon>
                {{ header }}
                <EditIcon
                  @click="showEdit = true"
                  class="ml-5 w-4 h-4 mt-2px animation-focus-default cursor-pointer"
                  v-if="onOwnProfile && (videoInfo && videoInfo.prompt)"
                ></EditIcon>
              </div>
              <div class="sm:text-0_8xl text-orange-400 font-bold">
                {{ (videoInfo && videoInfo.prompt) ? videoInfo.prompt.prompt : '' }}
              </div>
            </div>

            <div v-if="showEdit" class="relative p-4 sm:px-6 sm:py-2 sm:px-8 sm:max-w-400px sm:w-400px">
              <CloseIcon
                @click="showEdit = false"
                class="absolute top-2 sm:top-0 right-3 w-5 h-5 sm:w-4 sm:h-4 animation-focus-default cursor-pointer"
              ></CloseIcon>

              <template v-if="editOn">
                <div class="mt-4 sm:mt-0">
                  <span class="mr-4">
                      <RadioButton
                        id="radio1" label="Public - Every group member can view"
                        value="public" name="public" v-model="privacy"/>
                    </span>
                  <span class="mr-4">
                      <RadioButton
                        id="radio2" label="Private - Only I can view" value="private"
                        name="private" v-model="privacy"/>
                    </span>
                  <div class="flex mt-3">
                    <Button
                      :primary-full-size="false"
                      type="primary" compact
                      class="mr-2 h-40px w-1/2 sm:w-100px"
                      @onClick="editVideoPrivacy"
                    >
                      Save
                    </Button>
                    <Button
                      type="text" compact
                      class="ml-2 h-40px w-1/2 sm:w-100px"
                      @onClick="cancelEdit"
                    >
                      <span class="text-sm flex">Cancel</span>
                    </Button>
                  </div>
                </div>
              </template>

              <template v-else>
                <h3 class="font-semibold text-gray-800 text-sm uppercase mb-4 sm:mb-6">
                  {{ privacyMessage }}
                </h3>

                <div class="flex">
                  <Button
                    class="w-2/3 sm:w-auto mr-2"
                    type="text"
                    @onClick="toggleEdit()"
                  >
                    <span class="text-sm sm:text-base text-gray-800 flex">Edit Privacy</span>
                  </Button>

                  <Button
                    class="w-1/3 sm:w-auto ml-2"
                    type="text"
                    @onClick="openDeleteVideoDialog()"
                  >
                    <span class="text-sm sm:text-base text-gray-800 flex">Delete</span>
                  </Button>
                </div>
              </template>
            </div>
          </div>

          <button
            @click="openVideoPlayerDialog"
            class="w-full sm:w-260px h-150px relative">
            <video muted
                   preload="metadata"
                   class="w-full sm:w-260px h-150px object-cover rounded-r">
              <source :src="`${videoInfo.file}#t=0.1`" type="video/mp4">
            </video>
            <span class="play-icon absolute rounded-full -mr-1 bg-white w-12 h-12 flex justify-center items-center">
              <PlayIcon></PlayIcon>
            </span>
          </button>
        </div>

        <template v-if="videoPlayerOpen">
          <VideoPlayerModal
            :prompt="videoInfo && videoInfo.prompt && videoInfo.prompt.prompt"
            :handleRecordStory="handleRecordStory"
            :showCurrentUserVideoPrompt="showCurrentUserVideoPrompt && !safariDesktop"
            :video="videoInfo && videoInfo.file"
            :hideModal="closeVideoPlayerDialog"/>
        </template>
      </div>
    </template>

    <!--                     -->
    <!-- DIALOG REMOVE VIDEO -->
    <!--                     -->

    <transition name="component-fade" mode="out-in">
      <DialogDefault
        v-if="showDeleteVideoDialog"
        :icon="'delete'"
        :header="'Delete your video'"
        :text="'Are you sure you want to delete your video story?'"
        :applyBtnText="'Delete'"
        @cancel="cancelDelete"
        @apply="handleDeleteVideo"
      ></DialogDefault>
    </transition>

    <!--    <template v-if="error">-->
    <!--      <ErrorBox :message="error"/>-->
    <!--    </template>-->
  </div>
</template>

<script>
import VideoIcon from '@/assets/icon_video.svg';
import PlayIcon from '@/assets/icon_play.svg';
import EditIcon from '@/assets/icon_edit.svg';
import CloseIcon from '@/assets/icon_close.svg';
import VideoCameraIcon from '@/assets/icon_video_camera.svg';
import Button from '@/components/Button.vue';
import { mapActions, mapGetters } from 'vuex';
import RadioButton from '@/components/RadioButton.vue';
import VideoPlayerModal from '@/components/VideoPlayerModal.vue';
import DialogDefault from '@/components/DialogDefault.vue';

export default {
  name: 'ProfileVideoInfo',
  components: {
    VideoPlayerModal,
    RadioButton,
    DialogDefault,
    VideoIcon,
    PlayIcon,
    Button,
    VideoCameraIcon,
    EditIcon,
    CloseIcon,
  },
  props: {
    /**
     * is user on Safari on desktop (won't be able to record video)
     */
    safariDesktop: {
      type: Boolean,
      default: false,
    },
    /**
     * video file + info recorded by user
     */
    videoInfo: {
      type: Object,
      default: null,
    },
    /**
     * handle deleting user video
     */
    deleteVideo: {
      type: Function,
      required: true,
    },
    /**
     * user is viewing their own profile
     */
    onOwnProfile: {
      type: Boolean,
    },
    /**
     * determines whether we want to prompt user to record a video
     */
    showCurrentUserVideoPrompt: {
      type: Boolean,
    },
    /**
     * event handler for button for current user to record video
     */
    handleRecordStory: {
      type: Function,
    },
  },
  data() {
    return {
      editOn: false,
      privacy: 'public', // public, private
      error: null,
      showDeleteVideoDialog: false,
      showEdit: false,
    };
  },
  computed: {
    ...mapGetters(['videoModalOpen', 'isLoading', 'videoPlayerOpen']),
    header() {
      return this.onOwnProfile ? 'Your Video Story' : 'Video Story';
    },
    privacyMessage() {
      return this.privacy === 'public' ? 'PUBLIC  -  EVERY GROUP MEMBER CAN VIEW' : 'PRIVATE  -  ONLY YOU CAN VIEW';
    },
  },
  created() {
    if (this.videoInfo) {
      this.privacy = this.videoInfo.isVisible ? 'public' : 'private';
    }
  },
  methods: {
    ...mapActions([
      'updateVideoRecorderModal',
      'updateVideoPrivacy',
      'openVideoPlayer',
      'closeVideoPlayer',
    ]),
    openVideoPlayerDialog() {
      document.body.style.overflow = 'hidden';
      this.openVideoPlayer();
    },
    closeVideoPlayerDialog() {
      this.closeVideoPlayer();
      document.body.style.overflow = 'auto';
    },
    openDeleteVideoDialog() {
      this.showDeleteVideoDialog = true;
    },
    toggleEdit() {
      this.editOn = !this.editOn;
      this.error = null;
    },
    cancelDelete() {
      this.error = null;
      this.showDeleteVideoDialog = false;
    },
    cancelEdit() {
      this.editOn = false;
      this.error = null;
    },
    handleVideoResponse(res) {
      if (res.success) {
        this.showDeleteVideoDialog = false;
      } else {
        this.error = 'This change could not be saved. Please try again.';
      }
    },
    handleDeleteVideo() {
      this.error = null;
      this.deleteVideo(this.handleVideoResponse);
    },
    editVideoPrivacy() {
      this.error = null;
      const options = {
        groupSlug: this.$route.params.group,
        videoId: this.videoInfo.id,
        isVisible: this.privacy === 'public',
      };
      this.updateVideoPrivacy(options)
        .then((res) => {
          this.handleVideoResponse(res);
        });
    },
    openModal() {
      const openModal = true;
      this.updateVideoRecorderModal(openModal);
    },
  },
};
</script>

<style lang="scss" scoped>
.play-icon {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
