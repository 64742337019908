<template>
  <div>

    <Toast v-if="showToast">Group is updated!</Toast>

    <div class="max-w-screen-xl mx-6 sm:mx-auto py-9 lg:py-12 sm:w-full border-t border-gray-400 sm:border-0">

      <div class="flex space-between flex-col lg:flex-row">

        <div class="block lg:hidden w-full sm:w-320px">
          <Dropdown
            placeholder="Admins"
            :fullWidth="true"
            :custom-height="true"
            :options="adminList"
            class="admin-dropdown"
          >
            <div class="p-3">
              <router-link
                class="w-full animation-focus-default"
                :key="profile.id"
                v-for="(profile, index) in adminList"
                :to="{ name: 'public-profile', params: {
                       group: groupSlug,
                       user: profile.slug,
                } }"
              >
                <div class="flex px-1 py-5 border-gray-300 group"
                     :class="{'border-b': index !== adminList.length - 1, 'pt-1': index === 0, 'pb-2': index === adminList.length - 1}">
                  <img
                    class="w-17 h-17 rounded mr-7"
                    :src="profile.profilePicture"
                    alt="profile_picture"
                  />
                  <div class="flex flex-col justify-center">
                    <div class="text-base font-semibold mb-1 group-hover:text-primary">
                      {{ profile.firstName }} {{ profile.lastName }}
                    </div>
                    <div class="flex items-center text-gray-700 text-small">
                      <LocationIcon class="location-icon w-3 h-3 mr-1"></LocationIcon>
                      {{ getAdminLocation(profile) }}
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </Dropdown>
        </div>

        <!--            -->
        <!-- LEFT BLOCK -->
        <!--            -->

        <div class="flex flex-col w-full lg:w-2/3 mt-9 lg:mt-0 lg:mr-8">

          <!--             -->
          <!-- REVIEW MODE -->
          <!--             -->

          <div class="w-full" v-if="!inEditMode">
            <div class="flex flex-col bg-white rounded shadow p-6 lg:px-12 lg:py-8">
              <h1 class="font-normal text-2xl">
                {{ currentGroup.name }}
              </h1>
              <h6 v-if="currentGroup.subtitle"
                  class="text-gray-700 font-semibold pt-2 text-base"
              >
                {{ currentGroup.subtitle }}
              </h6>

              <VueEditor
                v-if="currentGroup.description"
                :editorOptions="{ modules: { toolbar: false } }"
                :disabled="true"
                class="disabled-text-editor text-base text-sans pt-5"
                v-model="currentGroup.description"
              ></VueEditor>

              <div v-if="currentGroup.donationLink" class="website-link py-4">
                <a class="underline text-orange-400" :href="currentGroup.donationLink">
                  {{ currentGroup.donationLink }}
                </a>
              </div>
            </div>


          </div>


        </div>

        <!--             -->
        <!-- RIGHT BLOCK -->
        <!--             -->

        <div class="hidden lg:flex flex-col w-1/3">
          <div class="admins-container bg-white rounded shadow h-auto px-8 pt-6 pb-3">
            <h1 class="text-2xl mb-1">Admins</h1>

            <div class="flex flex-wrap">
              <router-link
                class="w-full animation-focus-default"
                :key="profile.id"
                v-for="(profile, index) in adminList"
                :to="{ name: 'public-profile', params: {
                       group: groupSlug,
                       user: profile.slug,
                } }"
              >
                <div class="flex px-1 py-5 border-gray-300 group"
                     :class="{'border-b': index !== adminList.length - 1}"
                >
                  <img
                    class="w-17 h-17 rounded mr-7"
                    :src="profile.profilePicture"
                    alt="profile_picture"
                  />
                  <div class="flex flex-col justify-center">
                    <div class="text-base font-semibold mb-1 group-hover:text-primary">
                      {{ profile.firstName }} {{ profile.lastName }}
                    </div>
                    <div class="flex items-center text-gray-700 text-small">
                      <LocationIcon class="location-icon w-3 h-3 mr-1"></LocationIcon>
                      {{ getAdminLocation(profile) }}
                    </div>
                  </div>
                </div>
              </router-link>
            </div>

          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import Toast from '@/components/Toast.vue';
import { VueEditor } from 'vue2-editor';
import Dropdown from '@/components/Dropdown.vue';
import LocationIcon from '@/assets/icon_location.svg';

import { mapActions, mapGetters } from 'vuex';
import { getISODateToFormatted } from '../../filters';

export default {
  name: 'GroupDescription',
  components: {
    Toast,
    VueEditor,
    Dropdown,
    LocationIcon,
  },
  computed: {
    ...mapGetters([
      'isLoading',
      'group',
      'profiles',
      'user',
      'isLoading',
    ]),
    isAdmin() {
      const currentUser = this.profiles.slice()
        .filter((member) => member.id === this.user.id)[0];
      return (currentUser && currentUser.isGroupAdmin) ||
        (this.user && this.user.user && this.user.user.isSuperuser);
    },
    showHint() {
      return !this.isLoading &&
        Object.keys(this.currentGroup).length &&
        !this.currentGroup.description;
    },
  },
  data() {
    return {
      showToast: false,
      currentGroup: {},
      adminList: [],
      groupSlug: '',
      inEditMode: false,
      editErrors: [],
      days: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11,
        12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
        22, 23, 24, 25, 26, 27, 28, 29, 30, 31],
      months: ['January', 'February', 'March', 'April', 'May',
        'June', 'July', 'August', 'September', 'October',
        'November', 'December',
      ],
      viewingPastEvents: false,
      selectedAdmin: {},
      showRemoveFundraiserDialog: false,
      currentFundraiser: {},
    };
  },
  created() {
    const groupSlug = this.$route.params.group;
    this.groupSlug = groupSlug;
    if (!this.group || !this.group.id) {
      this.fetchGroup({ groupSlug })
        .then(() => this.setCurrentGroup());
    }
    if (!this.profiles.length) {
      this.fetchProfiles({ groupSlug })
        .then(() => {
          this.getAdminsFromList();
        });
    }
    this.setCurrentGroup();
    this.getAdminsFromList();
  },
  methods: {
    ...mapActions([
      'fetchGroup',
      'fetchProfiles',
      'fetchCurrentUser',
    ]),
    setCurrentGroup(group = null) {
      const groupToSet = group || this.group;
      if (groupToSet.eventDate) {
        this.currentGroup = {
          ...groupToSet,
          eventDate: getISODateToFormatted(groupToSet.eventDate),
        };
      } else {
        this.currentGroup = { ...groupToSet };
      }
    },
    getAdminsFromList() {
      if (this.profiles.length) {
        this.adminList = this.profiles.slice()
          .filter((member) => member.isGroupAdmin === true);
      }
    },
    getAdminLocation(profile) {
      return profile.city ? `${profile.city}, ${profile.state}` : 'City N/A';
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    selectAdmin(admin) {
      this.selectedAdmin = admin;
    },
  },
};
</script>

<style lang="scss" scoped>
.flex-2 {
  flex: 2;
}

.shadow {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
}
</style>

<style lang="scss">
.admin-dropdown {
  height: 60px;

  .trigger .placeholder {
    @apply font-serif font-normal text-0_8xl;
  }

  .menu-container {
    margin-top: 4px;
    max-height: 364px;

    .menu {

    }
  }
}

.location-icon path {
  stroke: #4C4E50;
}

#quill-container.ql-container.ql-snow {
  @apply border-none font-sans;
}

.quillWrapper .ql-toolbar.ql-snow {
  @apply border-none;
}

.disabled-text-editor #quill-container .ql-editor {
  @apply p-0;
  min-height: auto;

  a {
    @apply text-primary;
  }
}
</style>
