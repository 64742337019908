<template>
  <section
    ref="modal"
    class="flex w-full h-full fixed inset-0 outline-none z-50 justify-center items-center"
    tabindex="0"
    @keydown.esc="hideModal">
    <div class="bg-black opacity-80 w-full h-full fixed">
    </div>
    <div class="modal z-50 absolute top-136px sm:top-auto sm:mb-40">
      <div class="relative w-88 sm:w-100 flex flex-col">
        <button
        class="animation-focus-default font-bold text-white text-base font-sans mb-2 sm:-mr-7 sm:-mb-2 self-end"
        @click="hideModal">
          <CloseIcon class="close-icon w-5 h-5"></CloseIcon>
        </button>
        <p class="text-white font-normal font-serif text-base text-center mb-2">{{ prompt }}</p>
        <video
          controls
          autoplay
          class="w-88 h-88 sm:w-100 sm:h-100 object-cover rounded"
          :src="video"></video>
        <template v-if="showCurrentUserVideoPrompt">
          <Button
            class="mt-6"
            type="secondary"
            @onClick="handleRecordStory"
          >
            <span class="text-gray-100">Record your own video</span>
          </Button>
        </template>
      </div>
    </div>
  </section>
</template>

<script>
import Button from '@/components/Button.vue';
import CloseIcon from '@/assets/icon_close.svg';

export default {
  name: 'VideoPlayerModal',
  components: {
    Button,
    CloseIcon,
  },
  props: {
    /**
     * action to close modal
     */
    hideModal: {
      type: Function,
    },
    /**
     * link for video
     */
    video: {
      type: String,
    },
    /**
     * if user hasn't recorded a video, show prompt to do so
     */
    showCurrentUserVideoPrompt: {
      type: Boolean,
    },
    /**
     * action if user clicks prompt to record own video
     */
    handleRecordStory: {
      type: Function,
    },
    /**
     * prompt that was answered by current video
     */
    prompt: {
      type: String,
    },
  },
};
</script>

<style scoped>
  .modal {
    left: 50%;
    transform: translateX(-50%);
  }
  .icon {
    @apply absolute z-50 cursor-pointer;
    bottom: 5%;
    left: calc(50% - 40px);
  }

  .close-icon path {
    stroke: #ffffff;
  }
</style>
