<template>
  <div v-if="profile && profile.id">

    <div class="flex flex-col px-6 pb-8 sm:p-0">
      <div v-if="profile"
           class="bg-white shadow rounded px-6 pt-6 pb-9 sm:pl-12 sm:pt-8 sm:pr-8 sm:pb-12 sm:mt-14 mb-6">

        <!-- MOBILE HEADER -->

        <div class="lg:hidden">
          <div class="w-full flex flex-col justify-start sm:justify-between sm:flex-row">
            <h1 class="block">
              {{ profile.firstName }} {{ profile.lastName }}
            </h1>
            <p class="block mt-2" v-if="profile.previousFirstName || profile.previousLastName">
              <span class="text-gray-700">Previous Name:</span> <b>{{ profile.previousFirstName }}
              {{ profile.previousLastName }}</b>
            </p>
          </div>
        </div>

        <div class="flex flex-col-reverse lg:flex-row">
          <!--              -->
          <!-- USER CONTENT -->
          <!--              -->

          <div class="flex flex-col flex-3 flex-initial">

            <!-- DESKTOP HEADER -->

            <div class="w-full hidden lg:flex flex-col sm:flex-row justify-between">
              <h1 class="block">
                {{ profile.firstName }} {{ profile.lastName }}
              </h1>
              <p class="block lg:hidden mt-2" v-if="profile.previousFirstName || profile.previousLastName">
                <span class="text-gray-700">Previous Name:</span> <b>{{ profile.previousFirstName }}
                {{ profile.previousLastName }}</b>
              </p>
            </div>

            <p class="hidden lg:block mt-5 w-1/2 lg:w-full"
               v-if="profile.previousFirstName || profile.previousLastName">
              <span class="text-gray-700">Previous Name:</span> <b>{{ profile.previousFirstName }}
              {{ profile.previousLastName }}</b>
            </p>

            <template v-if="profile.bio">
              <div class="mt-9">
                <h3 class="mb-4 sm:mb-0">About</h3>
                <VueEditor
                  :editorOptions="{ modules: { toolbar: false } }"
                  :disabled="true"
                  :class="{
                   'h-auto max-h-max': !showReadMoreLabel,
                   'container-expanded': isReadMoreExpanded,
                    'textWrapperShadow max-h-150px h-150px': !isReadMoreExpanded && showReadMoreLabel
                  }"
                  class="disabled-text-editor overflow-hidden
                        text-lg text-gray-700 text-sans
                        pb-4 sm:py-4 order-2 sm:order-3"
                  v-model="profile.bio"
                ></VueEditor>

                <div @click="toggleReadMore"
                     v-if="showReadMoreLabel"
                     class="flex items-center cursor-pointer w-auto">
                  <ChevronUpIcon :class="{'down': !isReadMoreExpanded}" class="chevron-icon w-3 mr-2"></ChevronUpIcon>
                  <div class="text-sm font-semibold">
                    {{ isReadMoreExpanded ? 'Read less' : 'Read more' }}
                  </div>
                </div>
              </div>
            </template>

            <div class="mt-8 order-2 sm:order-3">
              <template v-if="profileHasVideo">
                <ProfileVideoInfo
                  :onOwnProfile="false"
                  :safariDesktop="safariDesktop"
                  :videoInfo="video" class="order-2 sm:order-3"
                />
              </template>
            </div>
          </div>

          <!--                 -->
          <!-- USER REFERENCES -->
          <!--                 -->

          <div class="flex-1 flex flex-col justify-start mt-9 sm:mt-6 lg:mt-0 lg:ml-16 w-full">
            <div class="w-auto sm:flex sm:justify-between lg:block">
              <div
                v-if="profile.profilePicture"
                class="relative sm:w-300px w-auto">
                <img
                  class="sm:w-300px w-full rounded"
                  :src="profile.profilePicture"
                  alt="headshot"
                />
                <div class="flex absolute top-0 right-0 mr-3 mt-3">
                  <div v-if="profile.isGroupAdmin" class="p-2 bg-white rounded-full">
                    <StarIcon :class="{'color-default': isColorDefault()}"
                      class="star-icon h-3 w-3"></StarIcon>
                  </div>
                </div>
              </div>

              <div class="w-full sm:ml-10 lg:ml-0 sm:w-300px lg:w-full">

                <div v-if="anySocialSource()"
                     :class="{'color-default': isColorDefault()}"
                     class="social-list items-center py-6 sm:pt-0 sm:pb-2 lg:py-6 flex border-b border-gray-200">
                  <a class="animation-focus-default" v-if="instagramUrl" :href="instagramUrl" target="blank">
                    <InstagramIcon class="w-5 h-5"></InstagramIcon>
                  </a>
                  <a class="animation-focus-default" v-if="facebookUrl" :href="facebookUrl" target="blank">
                    <FacebookIcon class="w-5 h-5"></FacebookIcon>
                  </a>
                  <a class="animation-focus-default" v-if="linkedinUrl" :href="linkedinUrl" target="blank">
                    <LinkedinIcon class="linkedin-icon w-5 h-5"></LinkedinIcon>
                  </a>
                  <a class="animation-focus-default" v-if="twitterUrl" :href="twitterUrl" target="blank">
                    <TwitterIcon class="w-5 h-5"></TwitterIcon>
                  </a>
                  <a class="animation-focus-default" v-if="profile.showEmail" :href="'mailto:' + profile.email"
                     target="blank">
                    <MailIcon class="w-5 h-5"></MailIcon>
                  </a>
                </div>

                <div class="w-full lg:mt-2">
                  <a class="flex items-center mt-4" v-if="profile.city || profile.state">
                      <div class="w-5 h-5 ml-1 mr-2 flex items-center"> 📍 </div>
                    <div class="font-semibold w-260px">
                      <span v-if="profile.city">{{ profile.city }}</span>
                      <span v-if="profile.state"><span
                        v-if="profile.state && profile.city">, </span>{{ profile.state }}</span>
                    </div>
                  </a>
                  <a class="flex items-center mt-4" :href="getEmailLink" v-if="profile.showEmail">
                    <div class="w-5 h-5 mr-3 flex items-center"> ✉️ </div>
                    <div class="truncate font-semibold w-260px">
                      {{ profile.email }}
                    </div>
                  </a>
                  <a @onClick="websiteLinkClicked" :title="profile.link"
                     class="flex items-center mt-4 break-words" :href="getWebsiteLink" v-if="profile.link">
                    <div class="w-5 h-5 mr-3 flex items-center"> 🌐 </div>
                    <div class="font-semibold w-260px">
                      {{ profile.link }}
                    </div>
                  </a>
                  <a class="flex items-center mt-4" v-if="profile.school">
                    <div class="w-5 h-5 mr-3 flex items-center">🏛️</div>
                    <div class="font-semibold w-260px">
                      {{ profile.school }}
                    </div>
                  </a>
                  <a class="flex items-center mt-4" v-if="profile.major">
                    <div class="w-5 h-5 mr-3 flex items-center"> 🏛️ </div>
                    <div class="font-semibold w-260px">
                      {{ profile.major }}
                    </div>
                  </a>
                  <a class="flex items-center mt-4" v-if="profile.employer">
                    <div class="w-5 h-5 mr-3 flex items-center"> 💼 </div>
                    <div class="font-semibold w-260px">
                      {{ profile.employer }}
                    </div>
                  </a>

                  <template v-if="profile.customGroupValues">
                    <a class="flex items-center mt-4" :href="field.slug" :key="field.slug"
                       v-for="field in displayedFields">
                      <div class="w-5 h-5 mr-3 flex items-center">{{ field.fieldIcon }}</div>
                      <div class="font-semibold w-260px">
                        {{ field.value }}
                      </div>
                    </a>
                  </template>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


        <div class="bg-white rounded shadow sm:mb-16 px-6 py-1 sm:py-0 sm:px-12">
          <ProfilesBanner class="sm:my-4" :max-profiles-count="maxProfilesCount" :profiles="groupMembers" :totalMemberCount="groupMembers.length" :isPublic="true"/>
        </div>
    </div>


  </div>
</template>

<script>
import StarIcon from '@/assets/icon_star.svg';
import InstagramIcon from '@/assets/icon_instagram.svg';
import FacebookIcon from '@/assets/icon_facebook.svg';
import LinkedinIcon from '@/assets/icon_linkedin.svg';
import TwitterIcon from '@/assets/icon_twitter.svg';
import MailIcon from '@/assets/icon_mail.svg';
import ChevronUpIcon from '@/assets/icon_chevron_up.svg';
import ProfileVideoInfo from '@/components/ProfileVideoInfo.vue';
import ProfilesBanner from '@/components/ProfilesBanner.vue';
import { VueEditor } from 'vue2-editor';
import { mapActions, mapGetters } from 'vuex';
import { DEFAULT_COLOR } from '@/components/constants/groupColors';

import { determineSocialMediaLink } from '../../helpers';

export default {
  name: 'Profile',
  components: {
    StarIcon,
    ProfileVideoInfo,
    ProfilesBanner,
    VueEditor,
    InstagramIcon,
    FacebookIcon,
    TwitterIcon,
    LinkedinIcon,
    ChevronUpIcon,
    MailIcon,
  },
  computed: {
    ...mapGetters([
      'currentProfile',
      'group',
      'isLoading',
      'profileFields',
      'profiles',
      'user',
      'videoModalOpen',
    ]),
    showHint() {
      return !this.isLoading &&
        Object.keys(this.profile).length &&
        !this.profile.city &&
        !this.profile.bio &&
        !this.profile.major &&
        !this.profile.employer &&
        !this.profile.link &&
        !this.profile.school;
    },
    currentUser() {
      // this.user doesn't have some of the details we need so use profile
      return this.profiles.slice()
        .filter((member) => member.id === this.user.id)[0];
    },
    getLocationText() {
      const {
        city,
        state,
      } = this.profile;
      const formattedCity = (city && state) ? `${city}, ` : city;
      const formattedState = state || '';
      return `${formattedCity}${formattedState}`;
    },
    getEmailLink() {
      return `mailto:${this.profile.email}`;
    },
    getWebsiteLink() {
      return `//${this.profile.link}`;
    },
    usersProfile() {
      return (!this.onUserProfile && this.currentUser && (this.user.id === this.profile.id));
    },
    profileHasVideo() {
      return this.profile.videos && this.profile.videos.length;
    },
    isSafariMobile() {
      const safariMobile = (/iPad|iPhone|iPod/.test(navigator.platform));
      // we're mainly concerned about safari here but we'll check for ie too
      const ie = /MSIE|Trident/.test(navigator.userAgent);
      return !!navigator.platform && (safariMobile || ie);
    },
    safariDesktop() {
      return !this.isSafariMobile &&
        navigator.userAgent.indexOf('Safari') !== -1 &&
        navigator.userAgent.indexOf('Chrome') === -1;
    },
    visibleCustomFieldSlugs() {
      return this.profileFields
        .filter((field) => field.showOnProfile)
        .map((field) => field.slug);
    },
    displayedFields() {
      if (this.onUserProfile) {
        return {};
      }
      return this.profile.customGroupValues
        .filter((field) => this.visibleCustomFieldSlugs.includes(field.fieldSlug) && field.value);
    },
    facebookUrl() {
      if (this.profile && this.profile.user) {
        if (this.profile.user.socialMedia) {
          const facebookUrls = this.profile.user.socialMedia.filter((x) => x.mediaType === 2);
          if (facebookUrls.length > 0) {
            return determineSocialMediaLink(facebookUrls[0].url, 2);
          }
        }
      }

      return null;
    },
    instagramUrl() {
      if (this.profile && this.profile.user) {
        if (this.profile.user.socialMedia) {
          const instagramUrl = this.profile.user.socialMedia.filter((x) => x.mediaType === 1);
          if (instagramUrl.length > 0) {
            return determineSocialMediaLink(instagramUrl[0].url, 1);
          }
        }
      }

      return null;
    },

    twitterUrl() {
      if (this.profile && this.profile.user) {
        if (this.profile.user.socialMedia) {
          const facebookUrls = this.profile.user.socialMedia.filter((x) => x.mediaType === 4);
          if (facebookUrls.length > 0) {
            return determineSocialMediaLink(facebookUrls[0].url, 4);
          }
        }
      }

      return null;
    },

    linkedinUrl() {
      if (this.profile && this.profile.user) {
        if (this.profile.user.socialMedia) {
          const facebookUrls = this.profile.user.socialMedia.filter((x) => x.mediaType === 3);
          if (facebookUrls.length > 0) {
            return determineSocialMediaLink(facebookUrls[0].url, 3);
          }
        }
      }

      return null;
    },
  },
  data() {
    return {
      editError: null,
      groupMembers: this.profiles,
      inEditMode: false,
      loading: false,
      profile: {},
      showCurrentUserVideoPrompt: false,
      showToast: false,
      video: null,
      showReadMoreLabel: false,
      isReadMoreExpanded: false,
      maxProfilesCount: 0,
      defaultColor: DEFAULT_COLOR,
    };
  },
  created() {
    this.fetchCurrentProfile();
    if (!this.onUserProfile) {
      this.fetchProfileFields({ groupSlug: this.$route.params.group });

      if (!this.profiles.length) {
        this.fetchGroupProfiles();
      } else {
        this.setGroupProfiles();
      }
    }

    setTimeout(() => {
      const editorContent = document.getElementsByClassName('ql-editor')[0];

      if (editorContent) {
        this.showReadMoreLabel = editorContent.offsetHeight < editorContent.scrollHeight;
      }
    }, 500);

    window.addEventListener('resize', this.screenSizeChange);
    this.setAmountOfProfiles();
  },
  destroyed() {
    window.removeEventListener('resize', this.screenSizeChange);
  },
  watch: {
    $route() {
      if (this.profile.slug && (this.$route.params.user !== this.profile.slug)) {
        this.fetchCurrentProfile();
      }
    },
  },
  methods: {
    ...mapActions([
      'addProfileToList',
      'closeVideoPlayer',
      'createProfileFieldValue',
      'deleteUserVideo',
      'fetchCurrentUser',
      'fetchCurrentUsersProfile',
      'fetchGroup',
      'fetchProfile',
      'fetchProfileFields',
      'fetchProfiles',
      'getAccount',
      'openVideoPlayer',
      'removeProfileFieldValue',
      'removeProfileFromList',
      'updateCurrentUser',
      'updateProfileFieldValue',
      'updateUser',
      'updateVideoRecorderModal',
    ]),
    isColorDefault() {
      return this.group ? this.group.primaryColor === this.defaultColor : true;
    },
    shouldPromptCurrentUserToRecord() {
      this.fetchCurrentUser()
        .then((res) => {
          /* we don't need error handling here because we'll default
              to just not showing the button */
          if (res.success) {
            this.fetchCurrentUsersProfile({
              groupSlug: this.$route.params.group,
              profileSlug: res.data.slug,
            })
              .then((res) => {
                // todo: this will be removed once fetchCurrentUser includes the video status
                if (res.success && res.data && res.data.videos) {
                  this.showCurrentUserVideoPrompt = !res.data.videos.length;
                }
              });
          }
        });
    },
    handleRecordStory() {
      this.closeVideoPlayer();

      this.$router.push({
        name: 'profile',
        params: {
          group: this.$route.params.group,
          user: this.user.slug,
        },
      });

      const openModal = true;
      this.updateVideoRecorderModal(openModal);
    },
    fetchGroupProfiles() {
      const groupSlug = this.$route.params.group;

      this.fetchProfiles({ groupSlug })
        .then((res) => {
          if (res.success) {
            this.setGroupProfiles();
          }
        });
    },
    fetchCurrentProfile() {
      const groupSlug = this.$route.params.group;
      const profileSlug = this.$route.params.user;
      // get profile being viewed
      this.fetchProfile({
        groupSlug,
        profileSlug,
      })
        .then((res) => {
          if (res.success) {
            this.profile = this.currentProfile;
          } else {
            // redirect if the user doesn't exist
            this.$router.replace({ name: 'public-members' });
          }
        });
    },
    setGroupProfiles() {
      this.groupMembers = this.profiles;
    },

    scrollToTop() {
      window.scrollTo(0, 0);
    },
    emailLinkClicked() {
      // Google Analytics Event
      this.$gtag.event('click_email_link', {
        event_category: 'profile',
        event_label: `${this.$route.params.group} / User clicked email link`,
      });
    },
    websiteLinkClicked() {
      // Google Analytics Event
      this.$gtag.event('click_website_link', {
        event_category: 'profile',
        event_label: `${this.$route.params.group} / User clicked website link`,
      });
    },
    updateVideo(video) {
      this.video = video;
    },
    anySocialSource() {
      return this.instagramUrl || this.facebookUrl || this.linkedinUrl || this.twitterUrl || this.profile.showEmail;
    },
    toggleReadMore() {
      this.isReadMoreExpanded = !this.isReadMoreExpanded;
      const editorContent = document.getElementsByClassName('quillWrapper')[0];

      if (editorContent) {
        editorContent.style.height = this.isReadMoreExpanded ? 'auto' : '150px';
      }
    },
    screenSizeChange() {
      this.setAmountOfProfiles();
    },
    setAmountOfProfiles() {
      if (window.innerWidth > 1024) {
        this.maxProfilesCount = 13;
      } else if (window.innerWidth > 576) {
        this.maxProfilesCount = 8;
      } else {
        this.maxProfilesCount = 5;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.check-icon {
  width: 11px;
  height: 13px;
  margin-top: -2px;
}

.chevron-icon.down {
  transform: rotate(180deg);
}

.flex-3 {
  flex: 3;
}

.shadow {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
}

.underlined {
  @apply text-primary relative;

  &::after {
    @apply absolute bottom-0 left-0 right-0 bg-primary;
    content: '';
    height: 1px;
  }
}

.social-list {

  &.color-default a {
    @apply hover:bg-defaultInteractive hover:shadow-defaultInteractive;

    &:hover {
      .linkedin-icon path {
        @apply fill-defaultInteractive;
      }
    }
  }

  a {
    @apply w-12 h-12 bg-gray-200 sm:bg-transparent lg:bg-gray-200 hover:bg-primary hover:shadow-primary rounded mr-4 flex justify-center items-center cursor-pointer;

    &:last-child {
      @apply mr-0;
    }

    &:hover {
      svg path {
        @apply stroke-white;
      }

      .linkedin-icon path {
        @apply fill-primary;
      }
    }
  }
}
</style>

<style lang="scss">
#quill-container.ql-container.ql-snow {
  @apply border-none font-sans;
}

.ql-editor {
  overflow-y: hidden !important;
}

.quillWrapper .ql-toolbar.ql-snow {
  @apply border-none;
}

.textWrapperShadow {
  position: relative;
  pointer-events: all;

  &::after {
    pointer-events: none;
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(0deg, #ffffff 0%, #ffffff 10%, rgba(255, 255, 255, 0) 40%);
  }
}

.disabled-text-editor #quill-container .ql-editor {
  @apply p-0;
  min-height: auto;
}

.container-expanded {
  height: auto;
  max-height: max-content;
}

.star-icon {
  &.color-default {
    path {
      @apply fill-defaultInteractive;
    }
  }
  path {
    @apply fill-primary;
  }
}
</style>
