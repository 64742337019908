var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex items-center flex-row",class:{ 'justify-between': _vm.isAttending }},[(_vm.showViewAllProfilesLink && !_vm.isAttending)?_c('div',{staticClass:"flex items-center flex-shrink-0 sm:mb-0 font-semibold mr-4 sm:mr-5 animation-focus-default",on:{"click":_vm.profileClick}},[_c('ArrowLeft',{staticClass:"icon-arrow-right rotate-180 text-gray-800 w-4 mr-1"}),_c('div',{key:"members",staticClass:"inline-block text-sm text-sans appearance-none cursor-pointer mx-2"},[_c('span',{staticClass:"text-gray-800 hidden sm:block"},[_vm._v("View all members")]),_c('span',{staticClass:"text-gray-800 block sm:hidden"},[_vm._v("All profiles")])])],1):_vm._e(),_c('div',{staticClass:"flex flex-row"},[(_vm.profiles.length)?_vm._l((_vm.filteredProfiles),function(profile,index){return _c('a',{key:profile.id,staticClass:"sm:w-12 flex-shrink-0 cursor-pointer animation-focus-default",class:[{ '-ml-3': index > 0, 'w-10': _vm.showViewAllProfilesLink && !_vm.isAttending }],on:{"click":function($event){return _vm.openGroupProfile(profile)},"mouseover":function($event){_vm.indexProfileHovered = index},"mouseleave":function($event){_vm.indexProfileHovered = null}}},[_c('div',{staticClass:"photo-wrapper",class:[
            { opaque: _vm.currentProfile(profile) },
            { 'my-4': _vm.useMargins },
            { 'color-default': _vm.isColorDefault() },
          ],style:(_vm.customBorderColor && _vm.indexProfileHovered === index
              ? { 'border-color': _vm.getBorderColor() }
              : {})},[_c('ProfilePhoto',{attrs:{"url":profile.profilePicture,"alt":_vm.getThumbnailAltText(profile)}}),(_vm.profiles.length > _vm.currentProfilesCount && index === _vm.currentProfilesCount - 1)?_c('div',{staticClass:"splash",on:{"click":function($event){$event.stopPropagation();return _vm.profileClick.apply(null, arguments)}}},[_c('div',{staticClass:"label"},[_vm._v("+"+_vm._s(_vm.totalMemberCount - _vm.currentProfilesCount))])]):_vm._e()],1)])}):_vm._l((_vm.currentProfilesCount),function(n){return _c('div',{key:`profile-image-loader-${n}`,staticClass:"w-17 h-17 flex-shrink-0"},[_c('div',{staticClass:"relative w-14 h-14 rounded my-4 flex-shrink-0 overflow-hidden"},[_c('ProfilePhoto',{staticClass:"opacity-0",attrs:{"url":""}})],1)])})],2),(_vm.isAttending)?_c('div',{staticClass:"flex items-center font-semibold animation-focus-default sm:ml-6"},[_c('a',{key:"members",staticClass:"inline-block text-sm text-gray-800 text-sans appearance-none cursor-pointer",on:{"click":_vm.showAll}},[_vm._v(" "+_vm._s(_vm.profiles.length)+" Attending ")]),_c('ArrowLeft',{staticClass:"sm:hidden attending-arrow-icon rotate-180 text-gray-800 ml-2"})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }