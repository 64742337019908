<template>
  <div class="group-page relative">
    <div
      class="top-controls absolute flex w-full pt-4 lg:pt-6 justify-between items-center z-50 2xl:w-2xl px-6 lg:px-30 3xl:px-20"
    ></div>

    <div
      class="banner relative overflow-hidden"
      :style="{
        'background-image': `url('${
          $route.name === 'event' ? photoUrl : group.groupPhoto ? group.groupPhoto : null
        }')`,
      }"
      :class="{
        'opacity-transition': isTransitionForEventPage,
        'h-60 lg:h-540px bg-transparent': $route.name === 'event' ? photoUrl : group.groupPhoto,
        'bg-primary h-180px lg:h-80': $route.name === 'event' ? !photoUrl : !group.groupPhoto,
      }"
    >
      <div
        class="relative banner-content 2xl:w-2xl 2xl:mx-auto h-full flex flex-col justify-end p-6 sm:pl-12 sm:py-4 sm:pr-6 lg:py-8 lg:px-30 3xl:px-20"
      >
        <div class="block z-10">
          <template>
            <h1 class="text-white truncate">{{ group.name }}</h1>
            <h4 v-if="group.subtitle" class="text-white truncate subtitle">{{ group.subtitle }}</h4>
          </template>

          <Badge
            v-if="$route.name === 'event' && eventDateFormatted"
            :is-white="true"
            :is-transparent="!photoUrl"
            class="group-card-badge text-white mt-3 sm:mb-2 lg:mb-4"
            :text="eventDateFormatted"
            :background="group.primaryColor"
          ></Badge>
          <Badge
            v-else-if="group.groupClassification && group.groupClassification.name"
            :is-white="true"
            :is-transparent="!group.groupPhoto"
            class="group-card-badge text-white mt-3 sm:mb-2 lg:mb-4"
            :icon="group.groupClassification.name.toLowerCase()"
            :text="group.groupClassification.name"
            :background="group.primaryColor"
          ></Badge>
        </div>
      </div>

      <img
        v-if="$route.name === 'event' && !isTransitionForEventPage"
        :src="photoUrl"
        id="eventBanner"
        class="banner-photo"
        alt="banner_photo"
      />
      <img
        v-else-if="group.groupPhoto && !isTransitionForEventPage"
        :src="group.groupPhoto"
        id="groupBanner"
        class="banner-photo"
        alt="banner_photo"
      />

      <div class="banner-contrast"></div>
      <div class="banner-blur"></div>
    </div>

    <div class="tabs-wrapper relative sm:px-12 lg:px-30 pb-32 mt-0 lg:mt-4">
      <div class="relative px-6 pt-6 sm:p-0">
        <DropdownTabsMobile class="mb-6" :current-value="currentPage">
          <ul>
            <li class="mb-1" @click="currentPage = 'Members'">
              <router-link key="members" :to="{ name: 'public-members' }" class="link">
                <div class="dropdown-tab-item">Members</div>
              </router-link>
            </li>
            <li class="mb-1" @click="currentPage = 'Group Info'">
              <router-link key="description" :to="{ name: 'public-description' }" class="link">
                <div class="dropdown-tab-item">Group Info</div>
              </router-link>
            </li>
          </ul>
        </DropdownTabsMobile>
      </div>

      <div class="tabs-wrapper flex flex-col-reverse max-w-screen-xl mx-6 sm:mx-auto relative">
        <ul
          class="hidden sm:flex nav-items-container text-xl text-gray-600 border-b border-gray-400 text-base relative"
        >
          <li class="tab">
            <router-link
              key="members"
              :to="{ name: 'public-members' }"
              class="inline-block w-full hover:text-primary focus:text-primary focus:outline-none transition-colors duration-200 ease-out"
            >
              <h6
                :style="{
                  'border-bottom-color':
                    $route.name === 'public-members' || $route.name === 'public-profile'
                      ? `rgba(${group.primaryColor})`
                      : 'transparent',
                }"
                class="inline-block pb-5 border-b-3"
              >
                Members
              </h6>
            </router-link>
          </li>
          <li class="tab">
            <router-link
              key="description"
              :to="{ name: 'public-description' }"
              class="inline-block w-full hover:text-primary focus:text-primary focus:outline-none transition-colors duration-200 ease-out"
            >
              <h6
                :style="{
                  'border-bottom-color':
                    $route.name === 'public-description'
                      ? `rgba(${group.primaryColor})`
                      : 'transparent',
                }"
                class="inline-block pb-5 border-b-3"
              >
                Group Info
              </h6>
            </router-link>
          </li>
        </ul>
      </div>

      <div class="child-pages-wrapper max-w-screen-xl sm:mx-auto">
        <router-view></router-view>
      </div>
    </div>

    <template v-if="inviteModalOpen">
      <transition name="component-fade" mode="out-in">
        <GroupInviteModal :hideModal="hideInviteModal"></GroupInviteModal>
      </transition>
    </template>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import Badge from '@/components/Badge.vue';
import DropdownTabsMobile from '@/components/DropdownTabsMobile.vue';
import GroupInviteModal from '@/components/GroupInviteModal.vue';
import { DEFAULT_COLOR, DEFAULT_INTERACTIVE_COLOR } from '@/components/constants/groupColors';

export default {
  name: 'Index',
  components: {
    DropdownTabsMobile,
    Badge,
    GroupInviteModal,
  },
  data() {
    return {
      showVideoPrompt: false,
      viewingPastEvents: false,
      profileInfo: {
        groupSlug: '',
        sort: '+last_name',
        filters: {},
      },
      currentPage: 'Members',
      isTransitionForEventPage: false,
      defaultColor: DEFAULT_COLOR,
      defaultButtonColor: DEFAULT_INTERACTIVE_COLOR,
    };
  },
  computed: {
    ...mapGetters([
      'user',
      'currentProfile',
      'isAuthenticated',
      'group',
      'currentEvent',
      'inviteModalOpen',
    ]),
    documentTitle() {
      return this.group.subtitle
        ? `${this.group.name} - ${this.group.subtitle}`
        : `${this.group.name}`;
    },
  },
  methods: {
    ...mapActions(['fetchProfiles', 'fetchGroup', 'updateModalOpen']),
    isColorDefault() {
      return this.group.primaryColor === this.defaultColor;
    },
    getButtonDefaultColor() {
      return this.defaultButtonColor;
    },
    hideInviteModal() {
      this.updateModalOpen(false);
      document.body.style.overflow = 'auto';
      localStorage.setItem('invite-modal-opened', 'true');
    },
    isSafariMobile() {
      const safariMobile = /iPad|iPhone|iPod/.test(navigator.platform);
      // we're mainly concerned about safari here but we'll check for ie too
      const ie = /MSIE|Trident/.test(navigator.userAgent);
      return !!navigator.platform && (safariMobile || ie);
    },
    safariDesktop() {
      return (
        !this.isSafariMobile &&
        navigator.userAgent.indexOf('Safari') !== -1 &&
        navigator.userAgent.indexOf('Chrome') === -1
      );
    },
    setCurrentTab() {
      switch (this.$route.name) {
        case 'members':
          this.currentPage = 'Members';
          break;
        case 'description':
          this.currentPage = 'Group Info';
          break;
        default:
          this.currentPage = 'Members';
      }
    },
  },
  watch: {
    $route(to, from) {
      document.title = this.documentTitle || to.meta.title;
      this.setCurrentTab();

      if (to.name === 'profile') {
        setTimeout(() => {
          document.querySelector('.child-pages-wrapper').scrollIntoView({
            behavior: 'smooth',
          });
        }, 500);
      }

      if (to.name === 'event' || from.name === 'event') {
        this.isTransitionForEventPage = true;

        setTimeout(() => {
          this.isTransitionForEventPage = false;
        }, 300);
      }
    },
    group() {
      document.title = this.documentTitle || this.$route().meta.title;
      this.profileInfo.groupSlug = this.group.slug;
      if (this.profileInfo.groupSlug) {
        if (this.user && this.user.slug) {
          this.fetchProfile({
            groupSlug: this.$route.params.group,
            profileSlug: this.user.slug,
          })
            .then((res) => {
              if (res.success) {
                this.showVideoPrompt =
                  !this.currentProfile.videos || !this.currentProfile.videos.length;
              }
            })
            .then(() => this.fetchProfiles(this.profileInfo));
        } else {
          this.fetchProfiles(this.profileInfo);
        }
      }
    },
  },
  created() {
    if (this.$route.params.group) {
      this.fetchGroup({ groupSlug: this.$route.params.group }).then((data) => {
        if (data && !data.success) {
          this.$router.push({ name: 'not-found' });
        }
      });
    }

    this.setCurrentTab();
  },
};
</script>

<style lang="scss">
.banner {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  opacity: 1;
  transition: all 0.1s ease-in-out;

  &.opacity-transition {
    opacity: 0.3;
  }

  .banner-contrast {
    @apply absolute inset-0;
    z-index: 3;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25));
  }

  .banner-blur {
    @apply absolute inset-0;
    z-index: 1;
    backdrop-filter: blur(12px) brightness(1.2);
  }

  .banner-content {
    z-index: 4;
  }

  .banner-photo {
    @apply absolute top-1/2 left-1/2;
    transform: translate(-50%, -50%);
    width: 1440px;
    height: 540px;
    max-width: none;
    z-index: 2;

    @media all and (max-width: 576px) {
      height: 240px;
      width: calc(240px * 2.67);
    }
  }
}

.tab {
  @apply flex text-left text-center items-center mr-8 lg:mr-10;
}

.open-modal-btn {
  @apply w-155px sm:w-auto flex justify-center items-center py-3 sm:py-2 px-4 sm:px-6 text-sm font-medium rounded border;
}

.router-link-active.link .dropdown-tab-item {
  @apply bg-gray-300;
}

.link .dropdown-tab-item {
  @apply p-3 hover:bg-gray-300 focus:bg-gray-300 rounded;
}

.top-controls {
  left: 50%;
  transform: translateX(-50%);
}

.camera-icon {
  path {
    stroke: #2a2b2e;
  }

  &.default {
    path {
      @apply stroke-orange;
    }
  }
}

.subtitle {
  font-family: 'Quando';
  font-weight: normal;
}
</style>
