<template>
  <div
    class="py-8 sm:py-12 mx-6 sm:mx-auto max-w-screen-xl border-t border-gray-300 sm:border-none"
  >
    <div class="flex flex-col sm:flex-row items-start sm:items-center justify-between">
      <div
        class="flex flex-col sm:flex-row sm:justify-between items-start sm:items-center sm:bg-transparent pb-4 sm:pb-8 rounded w-full"
      >
        <h3>{{ profiles.length }} {{ 'Member' | pluralize(profiles.length) }}</h3>

        <div class="mt-5 sm:mt-0 w-full sm:w-470px block relative">
          <TextInput
            id="search"
            placeholder="Search"
            label="Search"
            :is-search="true"
            :value="searchValue"
            :has-clear-icon="true"
            @focused="searchFocused"
            @input="updateSearch"
            @onKeyEnter="searchEnterHit(true)"
          >
            <template v-slot:icon> <SearchIcon class="w-4" /> </template>
          </TextInput>

          <div
            v-if="filteredProfiles.length && searchValue.length && showFilterResults"
            class="search-dropdown-container"
          >
            <div class="search-dropdown custom-scroll">
              <div
                :key="index"
                v-for="(profile, index) in filteredProfiles"
                :class="{ 'mb-4': index !== filteredProfiles.length - 1 }"
                class="search-profile-item"
              >
                <router-link
                  class="flex items-center cursor-pointer"
                  :to="{
                    name: 'public-profile',
                    params: {
                      group: group.slug,
                      user: profile.slug,
                    },
                  }"
                >
                  <img
                    class="rounded-full w-9 h-9 mr-4"
                    :src="profile.profilePicture"
                    alt="profile.profile_picture"
                  />
                  <div
                    v-html="
                      $options.filters.highlight(
                        profile.firstName + ' ' + profile.lastName,
                        searchValue
                      )
                    "
                    class="text-sm text-gray-500"
                  >
                    {{ profile.firstName }} {{ profile.lastName }}
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div>
      <div class="flex flex-wrap sm:min-h-364px" :class="{ 'min-h-170px': !isLoading }">
        <div
          class="hidden sm:flex justify-between flex-nowrap overflow-hidden w-full md:w-720px xl:w-970px 2xl:w-full"
          v-if="!profiles.length"
        >
          <LoadingProfileCard class="mx-auto" :key="emptyCard" v-for="emptyCard in Array(5)" />
        </div>

        <div v-if="!profiles.length" class="flex sm:hidden w-full h-150px">
          <LoadingProfileCard />
        </div>

        <div
          v-if="profiles.length"
          class="grid grid-cols-1 sm:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-4 sm:gap-7 lg:gap-5 2xl:gap-4 3xl:gap-9"
        >
          <ProfileCard
            v-for="profile in cardProfilesShown"
            :key="profile.id"
            :profile="profile"
            @click.native="profileLinkClicked"
            @setOnYourList="setOnYourList(profile)"
            :groupSlug="group.slug"
            :visibleFields="visibleCustomFieldSlugs"
            :showSchool="group.schoolShowYearbook"
            :showEmployer="group.employerShowYearbook"
            :showMajor="group.majorShowYearbook"
            showDataOverflow
            isPublic
            :isShowAdminLabel="groupFeatureFlags && groupFeatureFlags.createdByAdminLabelEnabled"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ProfileCard from '@/components/ProfileCard.vue';
import SearchIcon from '@/assets/icon_search.svg';
import TextInput from '@/components/TextInput.vue';
import LoadingProfileCard from '@/components/LoadingProfileCard.vue';

const SMALL_GROUP_COUNT = 15;

export default {
  name: 'Members',
  components: {
    LoadingProfileCard,
    ProfileCard,
    SearchIcon,
    TextInput,
  },
  computed: {
    ...mapGetters([
      'currentProfile',
      'group',
      'isAuthenticated',
      'isLoading',
      'profileFields',
      'profiles',
      'user',
      'welcomeModalOpen',
      'groupEvents',
      'groupFeatureFlags',
    ]),
    notAuthenticatedOrNotMember() {
      if (this.isSuperuser) return false;
      return !this.isAuthenticated || (this.isAuthenticated && !this.memberOfGroup);
    },
    visibleCustomFieldSlugs() {
      return this.profileFields.filter((field) => field.showOnYearbook).map((field) => field.slug);
    },

    cardProfilesShown() {
      if (this.profilesEnterHit) {
        return this.filteredProfiles;
      }
      return this.profiles;
    },
  },
  data() {
    return {
      filterAttending: false,
      filterOnList: false,
      filteredProfiles: [],
      searchDebounce: '',
      searchValue: '',
      SMALL_GROUP_COUNT,
      profileInfo: {
        groupSlug: '',
        sort: '+last_name',
        filters: {},
      },
      isPreviewEvents: true,
      isSearchFocused: false,
      showRemoveFundraiserDialog: false,
      currentFundraiser: {},
      profilesEnterHit: false,
      showFilterResults: true,
    };
  },
  methods: {
    ...mapActions(['fetchProfile', 'fetchProfileFields', 'fetchProfiles', 'fetchEventList']),
    updateSearch(search) {
      this.searchValue = search;
      clearTimeout(this.searchDebounce);
      this.searchDebounce = setTimeout(() => {
        // Google Analytics Event
        this.$gtag.event('searched_members', {
          event_category: 'members',
          event_label: `${this.$route.params.group} / ${this.searchValue}`,
        });

        this.filterProfiles();
      }, 400);
    },
    handleIsAttendingFilter(filter) {
      if (filter) {
        this.profileInfo.filters.attending_next_event_include = 1;

        // Google Analytics Event
        this.$gtag.event('is_attending_filter', {
          event_category: 'members',
          event_label: `${this.$route.params.group} / Filtered by is_attending`,
        });
      } else {
        delete this.profileInfo.filters.attending_next_event_include;
      }
      this.fetchProfiles(this.profileInfo);
    },
    filterProfiles() {
      this.filteredProfiles = this.profiles.filter((profile) => {
        const fullName = `${profile.firstName.toLowerCase()} ${profile.lastName.toLowerCase()}`;
        const fullAddress = `${profile.city && profile.city.toLowerCase()}, ${
          profile.state && profile.state.toLowerCase()
        }`;
        const fullPrevName = `${
          profile.previousFirstName && profile.previousFirstName.toLowerCase()
        } ${profile.previousLastName && profile.previousLastName.toLowerCase()}`;

        return (
          fullName.indexOf(this.searchValue.toLowerCase()) >= 0 ||
          fullAddress.indexOf(this.searchValue.toLowerCase()) >= 0 ||
          fullPrevName.indexOf(this.searchValue.toLowerCase()) >= 0 ||
          (profile.bio && profile.bio.toLowerCase().indexOf(this.searchValue.toLowerCase()) >= 0) ||
          (profile.country &&
            profile.country.toLowerCase().indexOf(this.searchValue.toLowerCase()) >= 0)
        );
      });
    },

    searchEnterHit(val) {
      this.profilesEnterHit = val;
      if (val) {
        this.showFilterResults = false;
      } else {
        this.showFilterResults = true;
      }
    },
    searchFocused(isFocused) {
      if (isFocused) {
        this.searchEnterHit(false);
      }
    },
    profileLinkClicked() {
      // Google Analytics Event
      this.$gtag.event('clicked_profile', {
        event_category: 'members',
        event_label: `${this.$route.params.group} / Clicked user profile card`,
      });
    },
  },
  watch: {
    profiles() {
      this.filterProfiles();
    },
    group() {
      this.profileInfo.groupSlug = this.group.slug;
      if (this.profileInfo.groupSlug) {
        this.fetchProfiles(this.profileInfo);
      }
    },
  },
};
</script>

<style lang="scss">
.member-card {
  box-shadow: 0 2px 4px rgba(17, 17, 17, 0.08);
  transition: max-height 0.1s cubic-bezier(0, 1, 0, 1);
  @apply w-full sm:w-227px h-150px max-h-150px sm:h-364px sm:max-h-364px flex sm:flex-col relative bg-white rounded;

  &.show-more {
    transition: max-height 0.1s ease-in-out;
    @apply h-auto max-h-full;
  }

  &:hover {
    box-shadow: 0 15px 24px rgba(17, 17, 17, 0.05);
  }

  .picture {
    left: 50%;
    transform: translateX(-50%);
    @apply w-auto h-full sm:h-227px min-w-150px absolute sm:relative;
  }
}

.circle {
  @apply rounded rounded-full bg-gray-100 w-16 h-16 items-center justify-center;
  background-color: #f6f6f6;
}

.matched-text {
  @apply text-gray-900;
}

.search-dropdown-container {
  @apply z-10 absolute rounded ring-1 ring-black ring-opacity-5 shadow-lg bg-white pr-2 mt-2 w-full;

  .search-dropdown {
    @apply overflow-y-auto max-h-240px p-6 w-full;
  }
}
</style>
