<template>
  <div class="relative w-full">
    <button
      @click="toggleTabsDropdown"
      v-click-outside="close"
      :class="{ 'block sm:hidden border-transparent': !allowDesktop, 'font-semibold': isBold }"
      class="animation-focus-default focus:outline-none bg-white h-56px border border-gray-300 rounded p-4 text-sm shadow-md w-full flex justify-between items-center"
    >
      <span v-if="!isSocial" class="">{{ currentValue }}</span>
      <span v-else>
        <span v-if="currentValue === 1">
          <span class="hidden lg:block">Instagram</span>
          <InstagramIcon class="lg:hidden w-5 h-5 mr-3"></InstagramIcon>
        </span>
        <span v-else-if="currentValue === 2">
          <span class="hidden lg:block">Facebook</span>
          <FacebookIcon class="lg:hidden w-5 h-5 mr-3"></FacebookIcon>
        </span>
        <span v-else-if="currentValue === 3">
          <span class="hidden lg:block">LinkedIn</span>
          <LinkedinIcon class="lg:hidden w-5 h-5 mr-3"></LinkedinIcon>
        </span>
        <span v-else-if="currentValue === 4">
          <span class="hidden lg:block">Twitter</span>
          <TwitterIcon class="lg:hidden w-5 h-5 mr-3"></TwitterIcon>
        </span>
      </span>
      <ChevronDownIcon
        :style="{ transform: showTabsDropdown ? 'rotateX(180deg)' : 'rotateX(0deg)' }"
        class="w-3"
      ></ChevronDownIcon>
    </button>

    <div
      class="dropdown-tabs absolute w-full rounded bg-white mt-1 z-50 shadow-md overflow-y-auto"
      :class="{ 'dropdown-hidden': !showTabsDropdown }"
    >
      <div class="p-2 border border-gray-300 rounded">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import ChevronDownIcon from '@/assets/chevron.svg';
import InstagramIcon from '@/assets/icon_instagram.svg';
import FacebookIcon from '@/assets/icon_facebook.svg';
import LinkedinIcon from '@/assets/icon_linkedin.svg';
import TwitterIcon from '@/assets/icon_twitter.svg';

export default {
  name: 'DropdownTabsMobile',
  components: {
    ChevronDownIcon,
    InstagramIcon,
    FacebookIcon,
    LinkedinIcon,
    TwitterIcon,
  },
  props: {
    currentValue: {
      type: [String, Number],
      default: '',
    },
    allowDesktop: {
      type: Boolean,
      default: false,
    },
    isBold: {
      type: Boolean,
      default: true,
    },
    isSocial: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showTabsDropdown: false,
    };
  },
  methods: {
    toggleTabsDropdown() {
      this.showTabsDropdown = !this.showTabsDropdown;
    },
    close() {
      this.showTabsDropdown = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.dropdown-tabs {
  transition: max-height 0.2s ease-in-out, transform 0.2s ease;
  max-height: 300px;
  overflow-y: hidden;
}

.dropdown-hidden {
  @apply max-h-0;
}
</style>
