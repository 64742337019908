<template>
  <div class="profile-card-empty flex sm:block w-full relative rounded sm:rounded-0">
    <div class="avatar-empty rounded-l sm:rounded-l-none sm:rounded-t"></div>
    <div class="description-empty"></div>

    <div class="h-cover sm:hidden"></div>
  </div>
</template>

<script>
export default {
  name: 'LoadingProfileCard',
};
</script>

<style scoped>
.profile-card-empty {
  max-width: 227px;
  margin-right: 16px;
  background-color: #ffffff;

  animation: none;
}

.avatar-empty {
  width: 227px;
  height: 227px;
  background-image: linear-gradient(100deg, rgba(255, 255, 255, 0) 10%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 100%),
  linear-gradient(lightgray 227px, transparent 0);
  background-repeat: no-repeat;
  background-size: 227px 227px, 227px 227px;
  background-position: -227px 0, 0 0;

  animation: shine-square 1s infinite;
}

.description-empty {
  margin: auto;
  width: 227px;
  height: 137px;
  background-image: linear-gradient(
    100deg, rgba(255, 255, 255, 0) 10%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 100%), linear-gradient(lightgray 25px, transparent 0), linear-gradient(lightgray 16px, transparent 0), linear-gradient(lightgray 16px, transparent 0), linear-gradient(lightgray 16px, transparent 0);
  background-repeat: no-repeat;
  background-size: 227px 227px, 184px 227px, 139px 227px, 139px 227px, 139px 227px;
  background-position: -227px 0, 20px 16px, 20px 53px, 20px 77px, 20px 101px;

  animation: shine-description 1s infinite;
}

@keyframes shine-square {
  to {
    background-position: 227px 0, 0 0;
  }
}

@keyframes shine-description {
  to {
    background-position: 227px 0,
    20px 16px,
    20px 53px,
    20px 77px,
    20px 101px;
  }
}

@keyframes shine-all {
  to {
    background-position: 500px 0, 0 0;
  }
}

@media all and (max-width: 576px) {
  .profile-card-empty {
    max-width: none;
    margin-right: 0;
  }

  .h-cover {
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    background-size: 500px 150px;
    background-position: -500px 0;
    background-image: linear-gradient(
      100deg, rgba(255, 255, 255, 0) 10%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 100%), none;
    animation: shine-all 1.2s infinite;
  }

  .avatar-empty {
    width: 130px;
    height: 150px;
    background-image: linear-gradient(100deg, rgba(255, 255, 255, 0) 10%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 100%),
    linear-gradient(lightgray 150px, transparent 0);
    background-size: 150px 150px, 150px 150px;
    background-position: -150px 0, 0 0;
    animation: none;
  }

  .description-empty {
    margin: 0;
    height: 150px;
    width: 175px;
    background-image: linear-gradient(
      100deg, rgba(255, 255, 255, 0) 10%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 100%), linear-gradient(lightgray 25px, transparent 0), linear-gradient(lightgray 16px, transparent 0), linear-gradient(lightgray 16px, transparent 0), linear-gradient(lightgray 16px, transparent 0);
    background-size: 175px 175px, 184px 175px, 120px 175px, 120px 175px, 120px 175px;
    background-position: -175px 5px, 24px 21px, 24px 62px, 24px 86px, 24px 110px;
    animation: none;
  }
}
</style>
